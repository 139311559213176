// src/components/CTA.js
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ctaBg } from "../assets";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";
import Form from "./Form";

function CTA() {
  return (
    <Box sx={{ backgroundColor: "primary.main500" }} id="about">
      
<Container maxWidth="lg">
        {/* Text Section */}
        <Grow in={true} timeout={1200} appear={true}>
          <Box mt={3}>
            <Typography variant="h4" gutterBottom>
              DISCOVER ENDLESS ENTERTAINMENT WITH FREE GAMES 
            </Typography>
            <Typography variant="h6" gutterBottom>
              Your Gateway to Gaming at purevista.com!
            </Typography>
            <Typography variant="body1" paragraph>
              Dive into a vast sea of online gaming opportunities offered by purevista, perfect for both individual and group entertainment. Experience fluid gameplay across any platform, whether on a PC, smartphone, or tablet. purevista caters to players of all ages, promising unforgettable moments of joy.
            </Typography>
            <Typography variant="body1" paragraph>
              ZERO INSTALLATION, NO COSTS - IMMEDIATE PLAY!
            </Typography>
            <Typography variant="body1" paragraph>
              Jump right into the action with your preferred games without the need for downloads or subscription fees. Embark on unique journeys with purevista specials like Mystic Quests, Valor Trials, Rapid Racer, and Ninja Loot.
            </Typography>
            <Typography variant="body1" paragraph>
              Discover crowd-pleasers such as: Labyrinth Legends, Master of Chess, Warrior's Duel, Brain Teasers, and many others!
            </Typography>
            <Typography variant="body1" paragraph>
              BECOME PART OF OUR DYNAMIC GAMING COMMUNITY!
            </Typography>
            <Typography variant="body1" paragraph>
              Looking to meet like-minded players? Register and immerse yourself in community-centric games like Empire Builders and Farm Kingdom. These titles stand out in the MMO sphere for their vibrant communities and large player bases, providing immersive social interactions.
            </Typography>
            <Typography variant="body1" paragraph>
              DIVE INTO OUR VAST GAME SELECTION!
            </Typography>
            <Typography variant="body1" paragraph>
              purevista is proud to offer a broad array of gaming categories to satisfy every player's preferences. Engage in thought-provoking puzzles, strategic card games, and intense .io battles. For those craving adrenaline, our two-player challenges and high-speed racing games are sure to thrill.
            </Typography>
          </Box>
        </Grow>
        <Form/>
      </Container>
    </Box>
  );
}

export default CTA;
